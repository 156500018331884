import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';
import { Small } from '../components/text';
import { Col } from '../components/grid';

const GradientDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-top: ${props => props.theme.spacing(5)};
  margin-bottom: ${props => props.theme.spacing(3)};
  background: ${props => props.theme.gradients.redOrange};
`;

const HeadshotImage = styled.img`
  width: 100%;
  border-radius: 100%;
  filter: brightness(97%);
  @media ${props => props.theme.breakpoints.md} {
    width: 50%;
  }
  @media ${props => props.theme.breakpoints.sm} {
    width: calc(200% / 3);
  }
`;

const TeamMemberWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 33%;
  margin-bottom: ${props => props.theme.spacing(12)};
  & .role {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const TeamMember = ({ name, role, i, headshot, background }) => {
  const imageRef = createRef();
  useEffect(() => {
    if (window === 'undefined') {
      // do nothing
    } else {
      imageRef.current.src = headshot;
      imageRef.current.alt = name;
    }
  }, []);

  return (
    <TeamMemberWrapper lg={2} lgOffset={i % 3 === 0 ? 2 : 1} mdOffset={0} md={4} sm={6} xs={12}>
      {/* <HeadshotImage src={headshot} alt={name} /> */}
      <HeadshotImage src={headshot} alt={name} ref={imageRef} />
      <GradientDot />
      <Small align="center" weight="medium">
        {name}
      </Small>
      <Small align="center" className="role">
        {role}
      </Small>
      <Small align="center">{background}</Small>
      {/*<Small align="center" css={{ opacity: 0.5, fontSize: '13px', lineHeight: 1.3 }}>{background}</Small>*/}
    </TeamMemberWrapper>
  );
};

export const Investor = ({ name, i, firm, headshot }) => {
  return (
    <TeamMemberWrapper lg={2} lgOffset={i % 3 === 0 ? 2 : 1} mdOffset={0} md={4} sm={6} xs={12}>
      <HeadshotImage src={headshot} alt={name} />
      <GradientDot />
      <Small align="center" weight="medium">
        {name}
      </Small>
      <Small align="center">{firm}</Small>
    </TeamMemberWrapper>
  );
};
